var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("center", [
    _c(
      "div",
      {
        staticClass: "animate__animated animate__bounceIn",
        staticStyle: { "font-size": "80px" },
      },
      [_vm._v("404")]
    ),
    _c("h3", { staticClass: "animate__animated animate__bounceIn" }, [
      _vm._v("您访问的页面不存在,请检查或联系管理员!"),
    ]),
    _c("hr", { staticStyle: { margin: "40px" } }),
    _c(
      "div",
      [
        _c(
          "el-button",
          {
            staticClass: "animate__animated animate__bounceIn",
            attrs: { type: "primary" },
            on: {
              click: function ($event) {
                return _vm.$router.push("/")
              },
            },
          },
          [_vm._v("首页")]
        ),
        _c(
          "el-button",
          {
            staticClass: "animate__animated animate__bounceIn",
            attrs: { type: "primary" },
            on: {
              click: function ($event) {
                return _vm.$router.back(-1)
              },
            },
          },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }